import React, { useState } from 'react'
import closeSuccessIcon from '../../assets/icons/close-white.svg'
// import closeWarnIcon from '../../assets/icons/arrow-right-warn.svg'
import closePrimaryIcon from '../../assets/icons/close-primary-icon.svg'

const Notification = ({ text, type = 'success', className }) => {
  const [visible, setVisible] = useState(true)

  const endIcons = {
    success: closeSuccessIcon,
    primary: closePrimaryIcon,
    // warn: closeWarnIcon,
  }

  return (
    <div
      className={`notification_container notification_container_${type} ${
        className || ''
      }`}
      style={{display: visible ? 'flex' : 'none'}}
    >
      <div className={'notification_text'}>{text}</div>
      <div className={'notification_suffix_icon'}>
        <img src={endIcons[type]} alt="" onClick={() => {
          console.log('ere');setVisible(false)
        }} />
      </div>
    </div>
  )
}

export default Notification
