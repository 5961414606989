import React, { useState } from 'react'
import LogoImage from '../../../assets/images/logo.svg'
import FlashLogoImage from '../../../assets/images/flash-logo.svg'
import ArrowRightWhiteIcon from '../../../assets/icons/arrow-right-white.svg'
import UnCheckedIcon from '../../../assets/icons/unchecked.svg'
import CheckedIcon from '../../../assets/icons/checked.svg'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

const Signup = () => {
  const [flashTrader, setFlashTrader] = useState(false)
  return (
    <div className="signup">
      <div className="grid">
        <div className="signup_wrapper">
          <div className="signup_form">
            <div className="signup_form_header">
              <img src={LogoImage} alt="" className="" />
              <span>My Business</span>
            </div>
            <div className="signup_form_content">
              <div className="signup_form_title">Sign Up</div>
              <div className="signup_form_description">
                Sign up your business to the best payments
              </div>
              <div className="form_field">
                <div className="form_field_label">Name</div>
                <div className="form_field_input">
                  <input type="text" placeholder="Enter Name" />
                </div>
              </div>
              <div className="form_field">
                <div className="form_field_label">Email address</div>
                <div className="form_field_input">
                  <input type="text" placeholder="Enter Email address" />
                </div>
              </div>
              <div className="form_field">
                <div className="form_field_label">Phone number</div>
                <div className="form_field_input">
                  <input type="text" placeholder="Enter Phone number" />
                </div>
              </div>
              <div className="signup_form_flash_trader">
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      defaultChecked
                      sx={{ padding: 0 }}
                      className="signup_form_flash_trader_checkbox"
                      name="flash_trader"
                      icon={<img src={UnCheckedIcon} alt="icon" />}
                      checkedIcon={<img src={CheckedIcon} alt="icon" />}
                    />
                  }
                  checked={flashTrader}
                  onChange={(e) => setFlashTrader(!flashTrader)}
                  label="Are you a Flash Trader?"
                  labelPlacement="end"
                />
              </div>
              {flashTrader && (
                <div className='signup_form_flash_trader_wrapper'>
                  <div className="form_field">
                    <div className="form_field_label">Flash Account Number</div>
                    <div className="form_field_input">
                      <input
                        type="text"
                        placeholder="Enter Flash Account Number"
                      />
                    </div>
                  </div>
                  <div className="form_field">
                    <div className="form_field_label">Business Name</div>
                    <div className="form_field_input">
                      <input type="text" placeholder="Enter Business Name" />
                    </div>
                  </div>
                  <div className="form_field">
                    <div className="form_field_label">Password</div>
                    <div className="form_field_input">
                      <input type="password" />
                    </div>
                  </div>
                  <div className="form_field">
                    <div className="form_field_label">Confirm Password</div>
                    <div className="form_field_input">
                      <input type="password" />
                    </div>
                  </div>
                </div>
              )}
              <button
                type="submit"
                className="button button_primary signup_button"
              >
                <img
                  src={ArrowRightWhiteIcon}
                  style={{ visibility: 'hidden' }}
                  alt=""
                />
                <span>Create Account</span>
                <img src={ArrowRightWhiteIcon} alt="" />
              </button>
              <div className="signup_form_forgot_password">
                Already have an account?
                <br /> <a href="/login">Click here</a> to Login
              </div>
            </div>
          </div>
          <div className="signup_footer">
            <span>© Flash Group 2022</span>
            <img src={FlashLogoImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Signup
