import React, { useState } from 'react'
import HomeGreenIcon from '../../../assets/icons/home-green.svg'
import PhoneRedIcon from '../../../assets/icons/phone-red.svg'
import KeyblueIcon from '../../../assets/icons/key-blue.svg'

const Account = () => {
  const [isUpdating, setIsUpdating] = useState(false)

  return (
    <div className="account">
      <div className="account_header">
        <div className="account_title">Account</div>
        <div className="account_balance">
          <div className="account_balance_title">BALANCE</div>
          <div className="account_balance_value">R 49.00</div>
        </div>
      </div>
      <div className="account_body">
        <div className="account_overview">
          <div className="account_overview_header">Overview</div>
          <div className="account_overview_items">
            <div className="account_overview_item">
              <img
                src={HomeGreenIcon}
                alt="Icon"
                className="account_overview_item_icon"
              />
              <div className="account_overview_item_text">Steve’s Spaza</div>
            </div>
            <div className="account_overview_item">
              <img
                src={PhoneRedIcon}
                alt="Icon"
                className="account_overview_item_icon"
              />
              <div className="account_overview_item_text">+2783 234 8274</div>
            </div>
            <div className="account_overview_item">
              <img
                src={KeyblueIcon}
                alt="Icon"
                className="account_overview_item_icon"
              />
              <div className="account_overview_item_text">
                User Code: <a href="tel:138764083710">138764083710</a>
              </div>
            </div>
          </div>
        </div>
        {isUpdating ? (
          <div className="account_information_updating">
            <div>
              <div className="account_information_updating_title">
                BUSINESS INFORMATION
              </div>
              <div className="account_information_updating_form">
                <div className="account_information_form_field">
                  <div className="account_information_form_field_label">
                    Name
                  </div>
                  <input
                    type="text"
                    className="account_information_form_field_input"
                  />
                </div>
                <div className="account_information_form_field">
                  <div className="account_information_form_field_label">
                    Business Name
                  </div>
                  <input
                    type="text"
                    className="account_information_form_field_input"
                  />
                </div>
                <div className="account_information_form_field">
                  <div className="account_information_form_field_label">
                    Email Address
                  </div>
                  <input
                    type="text"
                    className="account_information_form_field_input"
                  />
                </div>
                <div className="account_information_form_field">
                  <div className="account_information_form_field_label">
                    Account Number
                  </div>
                  <input
                    type="text"
                    className="account_information_form_field_input"
                  />
                </div>
              </div>
              <button
                className="button button_secondary account_update_btn"
                onClick={() => setIsUpdating(false)}
              >
                <span>Update</span>
              </button>
            </div>
            <div>
              <div className="account_information_updating_title">
                CHANGE PASSWORD
              </div>
              <div className="account_information_updating_form">
                <div className="account_information_form_field">
                  <div className="account_information_form_field_label">
                    First Name
                  </div>
                  <input
                    type="text"
                    className="account_information_form_field_input"
                  />
                </div>
                <div className="account_information_form_field">
                  <div className="account_information_form_field_label">
                    First Name
                  </div>
                  <input
                    type="text"
                    className="account_information_form_field_input"
                  />
                </div>
                <div className="account_information_form_field">
                  <div className="account_information_form_field_label">
                    First Name
                  </div>
                  <input
                    type="text"
                    className="account_information_form_field_input"
                  />
                </div>
                <div className="account_information_form_field">
                  <div className="account_information_form_field_label">
                    First Name
                  </div>
                  <input
                    type="text"
                    className="account_information_form_field_input"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="account_information">
            <div className="account_information_business">
              <div className="account_information_business_title">
                Business Information
              </div>
              <div className="account_information_business_field">
                <div className="account_information_business_label">Name</div>
                <div className="account_information_business_value">
                  Stevovs Stefofontein
                </div>
              </div>
              <div className="account_information_business_field">
                <div className="account_information_business_label">
                  Business Name
                </div>
                <div className="account_information_business_value">
                  Steve’s Spaza
                </div>
              </div>
              <div className="account_information_business_field">
                <div className="account_information_business_label">
                  Email Address
                </div>
                <div className="account_information_business_value">
                  steve@stevos.com
                </div>
              </div>
              <button
                className="button button_secondary account_update_btn"
                onClick={() => setIsUpdating(true)}
              >
                <span>Update</span>
              </button>
            </div>
            <div className="account_information_password">
              <div className="account_information_password_title">
                Change Password
              </div>
              <div className="account_information_password_form">
                <div className="account_information_form_field">
                  <div className="account_information_form_field_label">
                    First Name
                  </div>
                  <input
                    type="text"
                    className="account_information_form_field_input"
                  />
                </div>
                <div className="account_information_form_field">
                  <div className="account_information_form_field_label">
                    First Name
                  </div>
                  <input
                    type="text"
                    className="account_information_form_field_input"
                  />
                </div>
                <div className="account_information_form_field">
                  <div className="account_information_form_field_label">
                    First Name
                  </div>
                  <input
                    type="text"
                    className="account_information_form_field_input"
                  />
                </div>
                <div className="account_information_form_field">
                  <div className="account_information_form_field_label">
                    First Name
                  </div>
                  <input
                    type="text"
                    className="account_information_form_field_input"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Account
