import React from 'react'
import Modal from '@mui/material/Modal'
import CloseIcon from '../../../assets/icons/close-black.svg'
import UnCheckedIcon from '../../../assets/icons/unchecked-gray.svg'
import CheckedIcon from '../../../assets/icons/checked.svg'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

const FilterModal = ({ open, handleClose }) => {
  const paymentTypes = [
    {
      text: 'All',
      color: '#F6F6F6',
    },
    {
      text: 'Success',
      color: '#D9F3E9',
    },
    {
      text: 'Pending',
      color: '#ff8b0026',
    },
    {
      text: 'Partial Payment',
      color: '#3a7ef726',
    },
    {
      text: 'Failed',
      color: '#f9473926',
    },
    {
      text: 'Error',
      color: '#f9473966',
    },
  ]

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose()
        console.log('eere')
      }}
    >
      <div className="transactions_filter_modal">
        <div className="transactions_filter_modal_header">
          <span>Filter by type</span>
          <img src={CloseIcon} alt="Close" onClick={() => handleClose()} />
        </div>
        <div className="transactions_filter_modal_body">
          <div className="transactions_filter_modal_filter_types">
            {paymentTypes.map((type, index) => (
              <div
                className="transactions_filter_modal_filter_type"
                key={index}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      defaultChecked
                      sx={{ padding: 0 }}
                      className="transactions_filter_modal_filter_type_checkbox"
                      name="flash_trader"
                      icon={<img src={UnCheckedIcon} alt="icon" />}
                      checkedIcon={<img src={CheckedIcon} alt="icon" />}
                    />
                  }
                  sx={{ marginRight: 0, marginLeft: 0 }}
                  labelPlacement="end"
                />
                <div
                  className="transactions_table_user_payment_type"
                  style={{ backgroundColor: type.color }}
                >
                  {type.text}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default FilterModal
