import React from 'react'
import DownloadWhiteIcon from '../../../assets/icons/download-white.svg'
import TrushBlackIcon from '../../../assets/icons/trush-black.svg'
import PrintBlackIcon from '../../../assets/icons/print-black.svg'

const QRCodeItem = ({ image, title, size, amount, anyAmount }) => {
  return (
    <div className="qr_codes_item">
      <button className="qr_codes_item_delete_btn">
        <img src={TrushBlackIcon} alt="Trush" />
      </button>
      <img src={image} alt="qr code" className="qr_codes_item_image" />
      <div className="qr_codes_item_title">{title}</div>
      <div className="qr_codes_item_size">{size}</div>
      <div className={`qr_codes_item_amount${anyAmount ? ' anyamount' : ''}`}>
        {amount}
      </div>
      <button className="button button_primary download_btn">
        <img src={DownloadWhiteIcon} alt="Download" />
        <span>Download</span>
        <img
          src={DownloadWhiteIcon}
          style={{ visibility: 'hidden' }}
          alt="Download"
        />
      </button>
      <button className="button button_secondary button_secondary_outlined print_btn">
        <img src={PrintBlackIcon} alt="Print" />
        <span>Print</span>
        <img
          src={PrintBlackIcon}
          style={{ visibility: 'hidden' }}
          alt="Print"
        />
      </button>
    </div>
  )
}

export default QRCodeItem
