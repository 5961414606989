import React from 'react'
import LogoImage from '../../assets/images/logo.svg'
import ArrowLinkOrangeIcon from '../../assets/icons/arrow-link-orange.svg'
import ArrowLinkBlackIcon from '../../assets/icons/arrow-link-black.svg'

const Header = () => {
  return (
    <div className="main_layout_header">
      <div className="main_layout_header_left">
        <img src={LogoImage} alt="Logo" className="main_layout_header_logo" />
        <div className="main_layout_header_left_links">
          <a href="#">Home</a>
          <a href="#">Where to buy</a>
          <a href="#">Where to spend</a>
          <a href="#">Support</a>
          <a href="#">Contact</a>
        </div>
      </div>
      <div className="main_layout_header_right">
        <div className="main_layout_header_right_links">
          <a href="#">
            1ForYou app
            <img src={ArrowLinkBlackIcon} alt="Arrow" />
          </a>
          <a href="#">
            Business
            <img src={ArrowLinkBlackIcon} alt="Arrow" />
          </a>
        </div>
        <button className="main_layout_header_shopnow_btn">
          ShopNow
          <img src={ArrowLinkOrangeIcon} alt="Arrow" />
        </button>
      </div>
    </div>
  )
}

export default Header
