import React, { useState } from 'react'
import AccountIcon from '../../../assets/icons/account-white.svg'
import ArrowRightIcon from '../../../assets/icons/expand-orange.svg'
import ThreeDotsIcon from '../../../assets/icons/three-dots-black.svg'
import ArrowLeftIconBlack from '../../../assets/icons/arrow-left.svg'
import ArrowRightIconBlack from '../../../assets/icons/arrow-right.svg'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Notification from '../../../components/Common/Notification'
import Button from '@mui/material/Button'
import FilterModal from '../../../components/Dashboard/Transactions/FilterModal'

const Transactions = () => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [notification, setNotification] = useState({
    text: 'Payment Received',
    type: 'success',
    visible: true,
  })

  const transactionLists = [
    {
      customer: 'Stevovo',
      reference: 'TRU41628DG826',
      date: '25-10-2021',
      time: '9:30 AM ',
      paymentType: 'All',
      amount: 'R 300.00',
      color: '#F6F6F6',
    },
    {
      customer: 'Jim',
      reference: 'TRU41628DG826',
      date: '9:30 AM',
      time: '9:30 AM ',
      paymentType: 'Success',
      amount: 'R 20.00',
      color: '#D9F3E9',
    },
    {
      customer: 'Stevovo',
      reference: 'TRU41628DG826',
      date: '25-10-2021',
      time: '9:30 AM ',
      paymentType: 'Failed',
      amount: 'R 45.00',
      color: '#f9473926',
    },
    {
      customer: 'Stevovo',
      reference: 'TRU41628DG826',
      date: '25-10-2021',
      time: '9:30 AM ',
      paymentType: 'Pending',
      amount: 'R 1000.00',
      color: '#ff8b0026',
    },
    {
      customer: 'Stevovo',
      reference: 'TRU41628DG826',
      date: '25-10-2021',
      time: '9:30 AM ',
      paymentType: 'Pending',
      amount: 'R 300.00',
      color: '#ff8b0026',
    },
    {
      customer: 'Stevovo',
      reference: 'TRU41628DG826',
      date: '25-10-2021',
      time: '9:30 AM ',
      paymentType: 'Partial Payment',
      amount: 'R 300.00',
      color: '#3a7ef726',
    },
    {
      customer: 'Jim',
      reference: 'TRU41628DG826',
      date: '9:30 AM',
      time: '9:30 AM ',
      paymentType: 'Success',
      amount: 'R 20.00',
      color: '#D9F3E9',
    },
    {
      customer: 'Jim',
      reference: 'TRU41628DG826',
      date: '9:30 AM',
      time: '9:30 AM ',
      paymentType: 'Error',
      amount: 'R 20.00',
      color: '#f9473966',
    },
    {
      customer: 'Jim',
      reference: 'TRU41628DG826',
      date: '9:30 AM',
      time: '9:30 AM ',
      paymentType: 'Success',
      amount: 'R 20.00',
      color: '#D9F3E9',
    },
    {
      customer: 'Jim',
      reference: 'TRU41628DG826',
      date: '9:30 AM',
      time: '9:30 AM ',
      paymentType: 'Pending',
      amount: 'R 20.00',
      color: '#ff8b0026',
    },
  ]

  return (
    <div className="transactions">
      <Notification
        type={notification.type}
        text={notification.text}
        onClose={() => setNotification({ ...notification, visible: false })}
      />
      <div className="transactions_header">
        <div className="transactions_title">Transactions</div>
        <div className="transactions_balance">
          <div className="transactions_balance_title">BALANCE</div>
          <div className="transactions_balance_value">R 349.00</div>
        </div>
      </div>

      <TableContainer component="div" className="transactions_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow component="div" className="transactions_table_header_row">
              <TableCell component="div">CUSTOMER</TableCell>
              <TableCell component="div">REFERENCE</TableCell>
              <TableCell component="div">DATE</TableCell>
              <TableCell component="div">TIME</TableCell>
              <TableCell
                component="div"
                className="transactions_table_payment_header"
              >
                <span>PAYMENT TYPE:</span>
                <div className="select_payment" onClick={handleOpen}>
                  ALL
                  <img src={ArrowRightIcon} alt="" />
                </div>
              </TableCell>
              <TableCell component="div">AMOUNT</TableCell>
              <TableCell component="div"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionLists.map((transaction, index) => (
              <TableRow
                key={index}
                component="div"
                className="transactions_table_body_row"
              >
                <TableCell component="div">
                  <div className="transactions_table_user_icon">
                    <img src={AccountIcon} alt="" />
                  </div>
                  <div className="transactions_table_username">
                    {transaction.customer}
                  </div>
                </TableCell>
                <TableCell component="div">{transaction.reference}</TableCell>
                <TableCell component="div">{transaction.date}</TableCell>
                <TableCell component="div">{transaction.time}</TableCell>
                <TableCell component="div">
                  <div
                    className="transactions_table_user_payment_type"
                    style={{ backgroundColor: transaction.color }}
                  >
                    {transaction.paymentType}
                  </div>
                </TableCell>
                <TableCell component="div">{transaction.amount}</TableCell>
                <TableCell component="div">
                  <img
                    className="transactions_table_action"
                    src={ThreeDotsIcon}
                    alt=""
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="transactions_table_pagination">
        <button className="transactions_table_pagination_button">
          <img src={ArrowLeftIconBlack} alt="" />
        </button>
        <button className="transactions_table_pagination_button active">
          1
        </button>
        <button className="transactions_table_pagination_button">2</button>
        <button className="transactions_table_pagination_button">
          <img src={ArrowRightIconBlack} alt="" />
        </button>
      </div>
      <FilterModal open={open} handleClose={handleClose} />
    </div>
  )
}

export default Transactions
