import React from 'react'
import LogoImage from '../../assets/images/logo.svg'
import MenuIcon from '../../assets/icons/menu-orange.svg'
import QRCodeIcon from '../../assets/icons/qr-code-orange.svg'
import AccountIcon from '../../assets/icons/account-orange.svg'
import LogOutIcon from '../../assets/icons/log-out-orange.svg'
import { Link, useLocation } from 'react-router-dom'

const menus = [
  {
    path: '/dashboard/transactions',
    icon: MenuIcon,
    title: 'Transactions',
  },
  {
    path: '/dashboard/qr-codes',
    icon: QRCodeIcon,
    title: 'QR Codes',
  },
  {
    path: '/dashboard/account',
    icon: AccountIcon,
    title: 'Account',
  },
]

const SideBar = () => {
  const location = useLocation()
  console.log(location)
  return (
    <div className="dashboard_layout_sidebar">
      <div className="dashboard_layout_sidebar_wrapper">
        <img
          src={LogoImage}
          alt="Logo"
          className="dashboard_layout_sidebar_logo"
        />
        <div className="dashboard_layout_first_line_text">Business</div>
        <div className="dashbaord_layout_menus">
          {menus.map((e, index) => (
            <Link to={e.path} key={index}>
              <div
                className={`dashboard_layout_menu${
                  e.path === location.pathname ? ' active' : ''
                }`}
              >
                <img
                  src={e.icon}
                  alt="Icon"
                  className="dashboard_layout_menu_icon"
                />
                <span className="dashboard_layout_menu_text">{e.title}</span>
              </div>
            </Link>
          ))}
          <div className="dashboard_layout_menu">
            <img
              src={LogOutIcon}
              alt="Icon"
              className="dashboard_layout_menu_icon"
            />
            <span className="dashboard_layout_menu_text">Logout</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideBar
