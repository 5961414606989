import React from 'react'
import { Link } from 'react-router-dom'
import PlusOrangeIcon from '../../../assets/icons/plus-orange.svg'
import QRCodeImage from '../../../assets/images/temp/qr-code.png'
import QRCodeItem from '../../../components/Dashboard/QRCodes/QRCodeItem'

const qrCodes = [
  {
    image: QRCodeImage,
    title: 'Shop Code 1',
    size: '600 x 600px',
    amount: 'Any Amount',
    anyAmount: true,
  },
  {
    image: QRCodeImage,
    title: 'Shop Code 1',
    size: '600 x 600px',
    amount: 'R 300.00',
    anyAmount: false,
  },
  {
    image: QRCodeImage,
    title: 'Shop Code 1',
    size: '600 x 600px',
    amount: 'R 100.00',
    anyAmount: false,
  },
  {
    image: QRCodeImage,
    title: 'Shop Code 1',
    size: '600 x 600px',
    amount: 'R 140.00',
    anyAmount: false,
  },
  {
    image: QRCodeImage,
    title: 'Shop Code 1',
    size: '600 x 600px',
    amount: 'R 10.00',
    anyAmount: false,
  },
  {
    image: QRCodeImage,
    title: 'Shop Code 1',
    size: '600 x 600px',
    amount: 'R 300.00',
    anyAmount: false,
  },
]

const QRCodes = () => {
  return (
    <div className="qr_codes">
      <div className="qr_codes_header">
        <div className="qr_codes_title">QR Codes</div>
        <div className="qr_codes_balance">
          <div className="qr_codes_balance_title">BALANCE</div>
          <div className="qr_codes_balance_value">R 49.00</div>
        </div>
      </div>
      <div className="qr_codes_body">
        <Link
          to="/create-qr-code"
          style={{
            width: 'fit-content',
            textDecoration: 'none',
            display: 'flex',
          }}
        >
          <button className="button button_secondary qr_code_new_btn">
            <span>Create New</span>
            <img src={PlusOrangeIcon} alt="" />
          </button>
        </Link>
        <div className="qr_codes_lists">
          {qrCodes.map((code, index) => (
            <QRCodeItem {...code} key={index} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default QRCodes
