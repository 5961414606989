import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header'

const MainLayout = () => {
  return (
    <div className="main_layout">
      <div className="grid">
        <Header />
        <div className="main_layout_body">
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default MainLayout
