import axios from 'axios'

export const baseURL = process.env.REACT_APP_PROXY_API_URL

export const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
})

axiosInstance.interceptors.request.use(
    async (config) => {
        let updatedConfig = config;
        if (config.method === 'get') {
            updatedConfig.baseURL = baseURL + '/proxy_get/';
        } else if (config.method === 'post') {
            updatedConfig.baseURL = baseURL + '/proxy_post/';
        } else {
            updatedConfig.baseURL = baseURL + '/proxy_get/';
        }
        return updatedConfig
    },
    (error) => Promise.reject(error),
)
