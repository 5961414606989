import React from 'react'
import LogoImage from '../../assets/images/logo.svg'
import HomeArrowIcon from '../../assets/images/home-arrow.svg'
import ArrowRightWhiteIcon from '../../assets/icons/arrow-right-white.svg'
import ArrowRightBlackIcon from '../../assets/icons/arrow-right-black.svg'
import UnCheckedIcon from '../../assets/icons/unchecked.svg'
import CheckedIcon from '../../assets/icons/checked.svg'
import HomeWomanPhoneImg from '../../assets/images/home-woman-phone.png'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Link } from 'react-router-dom'

const Login = () => {
  return (
    <div className="home">
      <img src={HomeWomanPhoneImg} alt="" className="home_woman_img" />
      <div className="home_text">
        It’s
        <br /> business
        <br />
        <div>
          time
          <img src={HomeArrowIcon} alt="" />
        </div>
      </div>
      <div className="home_login_wrapper">
        <div className="login_form">
          <div className="login_form_header">
            <img src={LogoImage} alt="" className="" />
            <span>Business</span>
          </div>
          <div className="login_form_content">
            <div className="login_form_title">
              Sign up and start accepting payments{' '}
            </div>
            <div className="form_field">
              <div className="form_field_label">Username</div>
              <div className="form_field_input">
                <input type="text" placeholder="Enter username" />
              </div>
            </div>
            <div className="form_field">
              <div className="form_field_label">Password</div>
              <div className="form_field_input">
                <input type="password" placeholder="Enter password" />
              </div>
              <div className="form_field_hint">Hint text lorem ipsum</div>
            </div>
            <div className="login_form_remember_me">
              <FormControlLabel
                value="end"
                control={
                  <Checkbox
                    disableRipple
                    defaultChecked
                    sx={{ padding: 0 }}
                    className="login_form_remember_me_checkbox"
                    name="flash_trader"
                    icon={<img src={UnCheckedIcon} alt="icon" />}
                    checkedIcon={<img src={CheckedIcon} alt="icon" />}
                  />
                }
                label="Remember me"
                labelPlacement="end"
              />
            </div>
            <button
              type="submit"
              className="button button_primary login_button"
            >
              <img
                src={ArrowRightWhiteIcon}
                style={{ visibility: 'hidden' }}
                alt=""
              />
              <span>Log In</span>
              <img src={ArrowRightWhiteIcon} alt="" />
            </button>
            <Link to={'/signup'}>
              <button
                type="button"
                className="button button_secondary button_secondary_outlined signup_button"
              >
                <img
                  src={ArrowRightBlackIcon}
                  style={{ visibility: 'hidden' }}
                  alt=""
                />
                <span>Create account</span>
                <img src={ArrowRightBlackIcon} alt="" />
              </button>
            </Link>
            <div className="login_form_forgot_password">
              <a href="#">Forgot your password?</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
