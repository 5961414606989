import React, { useState } from 'react'
import LogoImage from '../../../assets/images/logo.svg'
import CreateQRImage from '../../../assets/icons/create-qr.svg'
import CloseBlackIcon from '../../../assets/icons/close-black.svg'
import InfoGrayIcon from '../../../assets/icons/info-gray.svg'
import CreateQRImageGray from '../../../assets/icons/create-qr-gray.svg'
import ExpandGrayIcon from '../../../assets/icons/expand-gray.svg'
import GalleryIcon from '../../../assets/icons/gallery.svg'
import ArrowRightOrangeIcon from '../../../assets/icons/arrow-right-orange.svg'
import QRCodeImage from '../../../assets/images/temp/qr-code.png'

const CreateQRCode = () => {
  const [success, setSuccess] = useState(false)

  return (
    <div className="create_qr_code">
      <div className="create_qr_code_logo">
        <img src={LogoImage} alt="Logo" />
        <span>Business</span>
      </div>
      {success ? (
        <div className="create_qr_code_success_form">
          <button className="create_qr_code_success_form_close_btn">
            <img src={CloseBlackIcon} alt="Icon" />
          </button>
          <div className="create_qr_code_success_form_title">
            QR Code Created
            <br /> Successfully
          </div>
          <div className="create_qr_code_success_form_description">
            View QR code
          </div>
          <div className="create_qr_code_success_form_image_wrapper">
            <img
              src={QRCodeImage}
              alt="QR code"
              className="create_qr_code_success_form_image"
            />
          </div>
          <button className="button button_secondary close_qr_code_btn">
            <img
              src={ArrowRightOrangeIcon}
              style={{ visibility: 'hidden' }}
              alt=""
            />
            <span>Close</span>
            <img src={ArrowRightOrangeIcon} alt="" />
          </button>
        </div>
      ) : (
        <div className="create_qr_code_form">
          <div className="create_qr_code_form_header">
            <img
              src={CreateQRImage}
              alt="Icon"
              className="create_qr_code_form_header_icon"
            />
            <button className="create_qr_code_form_close_btn">
              <img src={CloseBlackIcon} alt="Icon" />
            </button>
          </div>
          <div className="create_qr_code_form_body">
            <div className="create_qr_code_form_title">Create QR Code</div>
            <div className="create_qr_code_form_description">
              Generate a QR code here and start
              <br /> getting paid with 1Voucher
            </div>
            <div className="create_qr_code_form_group">
              <div className="create_qr_code_form_group_label">
                <span>QR Type</span>
                <img
                  src={InfoGrayIcon}
                  alt="Info"
                  className="create_qr_code_form_group_label_info"
                />
              </div>
              <div className="create_qr_code_form_group_control">
                <div className="create_qr_code_form_group_control_prefix">
                  <img src={CreateQRImageGray} alt="" />
                </div>
                <div className="create_qr_code_form_group_control_input">
                  <input type="text" />
                </div>
                <div className="create_qr_code_form_group_control_suffix">
                  <img src={ExpandGrayIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="create_qr_code_form_group">
              <div className="create_qr_code_form_group_label">
                <span>Enter Value</span>
              </div>
              <div className="create_qr_code_form_group_control">
                <div className="create_qr_code_form_group_control_prefix">
                  <span>R</span>
                </div>
                <div className="create_qr_code_form_group_control_input">
                  <input type="text" />
                </div>
              </div>
            </div>
            <div className="create_qr_code_form_group">
              <div className="create_qr_code_form_group_label">
                <span>Size in Pixels</span>
              </div>
              <div className="create_qr_code_form_group_control">
                <div className="create_qr_code_form_group_control_prefix">
                  <img src={GalleryIcon} alt="" />
                </div>
                <div className="create_qr_code_form_group_control_input">
                  <input type="text" />
                </div>
              </div>
            </div>
            <button
              className="button button_secondary generate_qr_code_btn"
              onClick={() => setSuccess(true)}
            >
              <img
                src={ArrowRightOrangeIcon}
                style={{ visibility: 'hidden' }}
                alt=""
              />
              <span>Generate QR Code</span>
              <img src={ArrowRightOrangeIcon} alt="" />
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default CreateQRCode
