import React from 'react';
import { Routes, Route } from 'react-router-dom'
import Home from './pages/Home';
import Login from './pages/Auth/Login';
import Signup from './pages/Auth/Signup';
import './App.css';

import './assets/fonts/AzoSans-Black.ttf';
import './assets/fonts/AzoSans-BlackItalic.ttf';
import './assets/fonts/AzoSans-Bold.ttf';
import './assets/fonts/AzoSans-BoldItalic.ttf';
import './assets/fonts/AzoSans-Italic.ttf';
import './assets/fonts/AzoSans-Light.ttf';
import './assets/fonts/AzoSans-LightItalic.ttf';
import './assets/fonts/AzoSans-Medium.ttf';
import './assets/fonts/AzoSans-MediumItalic.ttf';
import './assets/fonts/AzoSans-Regular.ttf';
import './assets/fonts/AzoSans-Thin.ttf';
import './assets/fonts/AzoSans-ThinItalic.ttf';
import MainLayout from './layouts/Main';
import DashboardLayout from './layouts/Dashboard';
import Transactions from './pages/Dashboard/Transactions';
import QRCodes from './pages/Dashboard/QRCodes';
import CreateQRCode from './pages/Dashboard/QRCodes/Create';
import Account from './pages/Dashboard/Account';

function App() {
  return (
    <Routes>
      <Route path='/' element={<MainLayout />}>
        <Route path='/' element={<Home />}></Route>
      </Route>
      <Route path='/login' element={<Login />}></Route>
      <Route path='/signup' element={<Signup />}></Route>
      <Route path='/dashboard' element={<DashboardLayout />}>
        <Route path='transactions' element={<Transactions />}></Route>
        <Route path='qr-codes' element={<QRCodes />}></Route>
        <Route path='account' element={<Account />}></Route>
      </Route>
      <Route path='/create-qr-code' element={<CreateQRCode />}></Route>
    </Routes>
  );
}

export default App;
