import React from 'react'
import LogoImage from '../../../assets/images/logo.svg'
import FlashLogoImage from '../../../assets/images/flash-logo.svg'
import ArrowRightWhiteIcon from '../../../assets/icons/arrow-right-white.svg'
import ArrowRightBlackIcon from '../../../assets/icons/arrow-right-black.svg'
import { Link, useNavigate } from 'react-router-dom'
import UnCheckedIcon from '../../../assets/icons/unchecked.svg'
import CheckedIcon from '../../../assets/icons/checked.svg'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { axiosInstance } from '../../../utils/api'

const validationSchema = yup.object({
  username: yup.string().required('Username is required'),
  password: yup.string().required('Password is required'),
})

const Login = () => {
  const navigate = useNavigate()
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: (formData) => {
      axiosInstance
        .get(`/v2/find/email?email=${formData.username}`)
        .then(({ data }) => {
          if (data.body && data.body.existingAccount) {
            handleLogin(formData)
          } else {
            navigate('/signup')
          }
        })
        .catch((error) => {
          console.log(error)
          navigate('/signup')
        })
    },
  })

  const handleLogin = ({ username, password }) => {
    axiosInstance
      .post('/login', {
        username,
        password,
        deviceModel: {
          appId: 'string',
          platform: 'WEB',
          platformVersion: 'string',
          platformVersionCode: 0,
          pushNotificationToken: 'string',
        },
      })
      .then((data) => {
        console.log(data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div className="login">
      <div className="grid">
        <div className="login_wrapper">
          <form onSubmit={formik.handleSubmit} className="login_form">
            <div className="login_form_header">
              <img src={LogoImage} alt="" className="" />
              <span>My Business</span>
            </div>
            <div className="login_form_content">
              <div className="login_form_title">Log in</div>
              <div className="login_form_description">
                Register to start accepting payments today.
              </div>
              <div className="form_field">
                <div className="form_field_label">Username</div>
                <div className="form_field_input">
                  <input
                    type="text"
                    placeholder="Enter username"
                    {...formik.getFieldProps('username')}
                  />
                </div>
              </div>
              <div className="form_field">
                <div className="form_field_label">Password</div>
                <div className="form_field_input">
                  <input
                    type="password"
                    placeholder="Enter password"
                    {...formik.getFieldProps('password')}
                  />
                </div>
                <div className="form_field_hint">Hint text lorem ipsum</div>
              </div>
              <div className="login_form_remember_me">
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      disableRipple
                      defaultChecked
                      sx={{ padding: 0 }}
                      className="login_form_remember_me_checkbox"
                      name="flash_trader"
                      icon={<img src={UnCheckedIcon} alt="icon" />}
                      checkedIcon={<img src={CheckedIcon} alt="icon" />}
                    />
                  }
                  label="Remember me"
                  labelPlacement="end"
                />
              </div>
              <button
                type="submit"
                className="button button_primary login_button"
              >
                <img
                  src={ArrowRightWhiteIcon}
                  style={{ visibility: 'hidden' }}
                  alt=""
                />
                <span>Log In</span>
                <img src={ArrowRightWhiteIcon} alt="" />
              </button>
              <Link to={'/signup'}>
                <button
                  type="button"
                  className="button button_secondary button_secondary_outlined signup_button"
                >
                  <img
                    src={ArrowRightBlackIcon}
                    style={{ visibility: 'hidden' }}
                    alt=""
                  />
                  <span>Sign Up</span>
                  <img src={ArrowRightBlackIcon} alt="" />
                </button>
              </Link>
              <div className="login_form_forgot_password">
                <a href="#">Forgot Password</a>
              </div>
            </div>
          </form>
          <div className="login_footer">
            <span>© Flash Group 2022</span>
            <img src={FlashLogoImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
